import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { dateToString } from "../../../../ReuseThese/JSDateTIme";
import "./ReceiverMessage.css";

const ReceiverMessage = ({ message }) => {
  return (
    <div>
      <div style={{ display: "flex", gap: "5px", padding: "0 10px" }}>
        <Avatar icon={<UserOutlined />} />
        <div className="receivermessage">
          <p style={{ display: "inline" }}>{message.message}</p>
        </div>
      </div>
      <span className="timeee">{dateToString(new Date(message.time))}</span>
    </div>
  );
};

export default ReceiverMessage;
