import React from "react";
import { Spin } from "antd";

const Spinner = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "90%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      
      <Spin tip="Oit Kandle" size="large" />
    </div>
  );
};

export default Spinner;
