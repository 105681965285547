import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import firebase, { db } from "../../firebase";
import Spinner from "../../ReuseThese/Spinner";
import "./Chat.css";
import LeftSide from "./LeftSide/LeftSide";
import RightSide from "./RightSide/RightSide";

const Chat = () => {
  const [mates, setMates] = useState([]);
  const [messanger, setMessanger] = useState(null);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useAuth();

  const userID = currentUser.claims.user_id;
  const userName = currentUser.claims.name;

  //get all mates if userID is in the array users
  useEffect(() => {
    db.collection("mates")
      .where("ids", "array-contains", userID)
      .orderBy("time", "desc")
      .onSnapshot((snapshot) => {
        const users = [];
        snapshot.docs.forEach((doc) => {
          users.push({
            id: doc.id,
            userId: doc.data().ids.find((id) => id !== userID) || userID,
            userName:
              doc.data().names.find((name) => name !== userName) || userName,
            time: doc.data().time,
          });
        });
        setMates(users);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="chat-box-daddy">
          <LeftSide
            mates={mates}
            setMates={setMates}
            messanger={messanger}
            setMessanger={setMessanger}
            userID={userID}
            userName={userName}
          />
          <RightSide messanger={messanger} userID={userID} />
        </div>
      )}
    </>
  );
};

export default Chat;
