import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAzWxo6gl2jcKfqehtV-7aMzzhDk6Fz9pU",
  authDomain: "aaru-bd.firebaseapp.com",
  projectId: "aaru-bd",
  storageBucket: "aaru-bd.appspot.com",
  messagingSenderId: "640268712776",
  appId: "1:640268712776:web:d61b9faa6db2b89064917d",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const storageRef = firebase.storage().ref();
export const db = firebase.firestore();
export const googleOAuth = new firebase.auth.GoogleAuthProvider();
export const facebookOAuth = new firebase.auth.FacebookAuthProvider();

export default firebase;
