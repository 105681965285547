import React, { useEffect } from "react";
import "./Winner.css";
import confetti from "canvas-confetti";
import hi from "../../../assets/hi.gif";
import talent from "../../../assets/talent.gif";
import eat from "../../../assets/eat.gif";
import eatAlot from "../../../assets/eat-alot.gif";
import eattt from "../../../assets/eattt.gif";
import buff from "../../../assets/buff.jpg";
import rude from "../../../assets/rude.gif";
import rudde from "../../../assets/rudde.gif";
import yawn from "../../../assets/yawn.gif";
import cute3 from "../../../assets/laugh cute.gif";
import emotions from "../../../assets/emotions.gif";
import focused from "../../../assets/focused.gif";
import sad from "../../../assets/sad.gif";
import cute from "../../../assets/cute.gif";
import cute2 from "../../../assets/cute2.gif";
import masskeko from "../../../assets/masskeko.gif";
import maskeko from "../../../assets/maskeko.gif";
import maskekko from "../../../assets/maskekko.gif";
import maskekeko from "../../../assets/maskekeko.gif";
import tongue from "../../../assets/tongue.gif";

const Winner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        width="1920"
        height="1080"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  useEffect(() => {
    var duration = 10 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var colors = ["#bb0123", "#ffffff", "#f123d1"];

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 90 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          colors: colors,
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          colors: colors,
        })
      );
    }, 250);
  }, []);

  return (
    <div className="winner-component">
      <h1>So, Bakhu Passed wiiiiiiiiii.</h1>
      <span className="t-1">
        Now, for your prize, lemme introduce you to someone.
      </span>
      <div className="img-div">
        <img loading="lazy" src={hi} alt="" />
      </div>
      <span className="t-1">Her name is Aaro Poudel.</span>
      <span className="t-1"> And She is a badass.</span>
      <div className="img-div">
        <img loading="lazy" src={buff} alt="" />
      </div>
      <span className="t-1">
        But dont let that badassary fool you. She's also very talented.
      </span>
      <br />
      <span className="t-1">Now, I know its hard to believe but she held,</span>
      <div className="img-div">
        <img loading="lazy" src={talent} alt="" />
      </div>
      <span className="t-1">
        this bottle on her head for several minutes (i didnt time it, but it was
        like weirdly long. like, i was kinda impressed fr)
      </span>
      <br />
      <br />
      <br />

      <hr />

      <br />
      <br />
      <br />
      <span className="t-1">
        Also, Aaro's really smart but I aint got gifs of her being smart so,
        watch her eat shit.{" "}
      </span>
      <div className="img-div">
        <img loading="lazy" src={eat} alt="" />
      </div>
      <span className="t-1">
        Bitch brings shit into her room and eats it. Dekhai dekhai. 😠😠
      </span>
      <div className="img-div">
        <img loading="lazy" src={eattt} alt="" />
      </div>
      <span className="t-1">I mean, look at this.</span>
      <div className="img-div">
        <img loading="lazy" src={eatAlot} alt="" />
      </div>

      <br />
      <br />
      <br />

      <hr />

      <br />
      <br />
      <br />

      <span className="t-1">
        Annnnnd, she's rude. So rude. Malai middle finger dekhako dekhai
        garchhe.
      </span>
      <div className="img-div">
        <img loading="lazy" src={rude} alt="" />
        <img loading="lazy" src={rudde} alt="" />
      </div>
      <span className="t-1">
        Also, Malai Harry Potter herna bolauchhe, ali afai "HAAA AAAA AAAAA
        <br />
        AAAAAA AAAAI" garera nidauchhe.
      </span>
      <div className="img-div">
        <img loading="lazy" src={yawn} alt="" />
      </div>
      <span className="t-1">
        Also maybe she's bipolar. WTF is up with these emotion changes?
      </span>
      <div className="img-div">
        <img loading="lazy" src={emotions} alt="" />
      </div>
      <span className="t-1">
        Sometimes looks like she's planning the next 9-11. abui kasto intense
        hou.
      </span>
      <div className="img-div">
        <img loading="lazy" src={focused} alt="" />
      </div>
      <span className="t-1">
        Kailei kai gaali garnu parchha, natra dherr matinchhee
      </span>
      <div className="img-div">
        <img loading="lazy" src={sad} alt="" />
      </div>
      <br />
      <br />
      <br />

      <hr />

      <br />
      <br />
      <br />
      <span className="t-1">
        Yeah bro, all in all she super weird.
        <br />
        but then bitch is also kinda low key cute.
      </span>
      <div className="img-div">
        <img loading="lazy" src={cute} alt="" />
        <img loading="lazy" src={cute2} alt="" />
      </div>
      <span className="t-1">Not even low key, really. Just cute.</span>
      <div className="img-div">
        <img loading="lazy" src={maskekeko} alt="" />
        <img loading="lazy" src={maskekko} alt="" />
      </div>
      <span className="t-1">Wow i got a lot of the cute ones.</span>
      <div className="img-div">
        <img loading="lazy" src={masskeko} alt="" />
        <img loading="lazy" src={tongue} alt="" />
        <img loading="lazy" src={maskeko} alt="" />
        <img loading="lazy" src={cute3} alt="" />
      </div>
      <span className="t-1">
        Aight Thats it i think. Well, Happy Borthday to ya. And, you did won so
        here's this.
      </span>

      <YoutubeEmbed embedId="4JxBwjnszzc" />
    </div>
  );
};

export default Winner;
