import React, { useEffect, useRef, useState } from "react";
import { db } from "../../../firebase";
import ChatInput from "./ChatInput/ChatInput";
import ReceiverMessage from "./Messages/ReceiverMessage";
import SenderMessage from "./Messages/SenderMessage";
import "./RightSide.css";

const RightSide = ({ messanger, userID }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (messanger === null || !messages) {
      return;
    }

    console.log([messanger, userID].sort().join(""), "messanger");

    db.collection("messages")
      .where("uid", "==", [messanger, userID].sort().join(""))
      .orderBy("time", "desc")
      .limit(20)
      .onSnapshot((snapshot) => {
        const messagesList = [];
        snapshot.docs.forEach((doc) => {
          console.log(doc.data());
          messagesList.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setMessages(messagesList.reverse());
        setLoading(false);
      });
  }, [messanger]);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    console.log("second", messanger);
  }, [messanger]);

  useEffect(scrollToBottom, [messages]);

  return (
    <div className="chatarea">
      <div className="messages">
        <div className="header">
          {messanger ? (
            <h1>Your conversations</h1>
          ) : (
            <h1>Select a conversation</h1>
          )}
        </div>
        <div className="chatarea__messages">
          {messages.length < 1
            ? ""
            : messages?.map((message, index) => {
                return message.sender === userID ? (
                  <SenderMessage key={index} message={message} />
                ) : (
                  <ReceiverMessage key={index} message={message} />
                );
              })}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="message-box">
        {messanger ? <ChatInput messanger={messanger} userID={userID} /> : ""}
      </div>
    </div>
  );
};

export default RightSide;
