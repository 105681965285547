import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";

const Card = ({ q, a, sound }) => {
  const [fliped, setfliped] = useState(false);

  const flipClicked = () => {
    if (!fliped) {
      new Audio(sound).play();
      setfliped(!fliped);
    }
  };

  return (
    <ReactCardFlip flipDirection="horizontal" isFlipped={fliped}>
      <div className="flip-card front" onClick={flipClicked}>
        {q}
      </div>

      <div className="flip-card back" onClick={flipClicked}>
        {a}
      </div>
    </ReactCardFlip>
  );
};

export default Card;
