import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "antd/dist/antd.css";
import AuthProvider from "./contexts/AuthContext";
import DataProvider from "./contexts/DataContext";

ReactDOM.render(
  <AuthProvider>
    <DataProvider>
      <App />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </DataProvider>
  </AuthProvider>,
  document.getElementById("root")
);
