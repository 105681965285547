import React from "react";
import "./Apology.css";
import Card from "./Card/Card";

import clap from "./clap.mp3";
import stupid from "./stupid.mp3";
import cry from "./cry.mp3";
import boing from "./boing.mp3";
import eww from "./eww.mp3";
import wow from "./wow.mp3";
import vomit from "./vomit.mp3";
import thank from "./thank.mp3";

const Apology = () => {
  return (
    <div className="sory">
      <h2>
        As with all the best SOPs I would like to start this apology with a
        quote. "To err is human, to forgive devine." Now, the question we have
        to ask ourself is "Is aaru Devine?"
      </h2>
      <h2>Let us compare 🤔🤔</h2>

      <div className="diff">
        <div className="aaru">
          <Card
            q="What ich deepum IQ?"
            a="Zero 😂😂 estup boy"
            sound={stupid}
          />
          <Card q="How is deepum pp?" a="smal 🤏🤏🤪🤪🤪" sound={cry} />
          <Card
            q="how is deepum buttle?"
            a="flat and smell of monkey poop 🙈🚫🍑"
            sound={eww}
          />
          <Card
            q="how is deepum face out of 10?"
            a="is the lowest point 0? otherwich it is minus infility 🤢🤢🤮🤮🤮"
            sound={vomit}
          />
        </div>
        <div className="deep">
          <Card
            q="What ich aaru IQ?"
            a="Infilite 🤯🤯 Very smat  girl"
            sound={clap}
          />
          <Card
            q="how is aaru bups?"
            a="big buple 👅👅 squishy"
            sound={boing}
          />
          <Card
            q="how is aaru buttle?"
            a="big and nice and smell of lavender 👅👅 squishy"
            sound={wow}
          />
          <Card
            q="how is aaru face out of 10?"
            a="numbers cannot describe the beauty of the cutest woman in world 💗💗, she is apsara 😳😍😍"
            sound={thank}
          />
        </div>
      </div>

      <h2>
        In conclusion, aaru is the cutest woman and deepum is retad. aaru
        shouldnt hold vengence(like batman) and forgip him. Thank you.
      </h2>
      <a style={{ cursor: "pointer" }} href="mailto:deepumpoudel@gmail.com">
        Please click HERE to forgip and send message
      </a>
      <a style={{ cursor: "pointer" }} href="https://www.messenger.com/t/100001819998224">
        If above not work click here
      </a>
    </div>
  );
};

export default Apology;
