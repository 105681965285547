import "./App.css";
import Home from "./Pages/Home/Home";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Winner from "./Pages/Home/Winner/Winner";
import Wordle from "./Pages/Wordle/Wordle";
import Apology from "./Pages/Home/Apology/Apology";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Login from "./Auth/Login";
import { Button } from "antd";
import { useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./Auth/PrivateRoute";
import Chat from "./Pages/Chat/Chat";
import DonkeyBrains from "./Pages/DonkeyBrains/DonkeyBrains";

function App() {
  const { logOut, currentUser } = useAuth();


  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Portfolio />
          </Route>
          <PrivateRoute
            component={Home}
            exact
            path="/buttle-happy-buttle"
          ></PrivateRoute>
          <PrivateRoute component={Chat} exact path="/chat"></PrivateRoute>
          <PrivateRoute component={Winner} path="/you-win"></PrivateRoute>
          <PrivateRoute component={Wordle} path="/wordle"></PrivateRoute>
          <PrivateRoute component={Apology} path="/apology"></PrivateRoute>
          <PrivateRoute component={DonkeyBrains} path="/donkey-brains"></PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </BrowserRouter>
      {currentUser ? (
        <div className="logout">
          <Button onClick={logOut}>Logout</Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
