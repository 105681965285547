import React, { useState } from "react";
import { toast } from "react-toastify";
import "./Questions.css";

const Questions = ({
  question,
  a1,
  a2,
  a3,
  a4,
  correct,
  addPoint,
  subtractPoint,
  add,
  reduce,
  setNumberOfPieces,
  setGravity,
  correctMessage,
  inCorrectMessage,
  addAnswered,
}) => {
  const [value, setValue] = useState("");
  const [afterAnswer, setafteranswer] = useState("");
  const [optionClass, setOptionClass] = useState("option");
  const [clicked, setClicked] = useState(false);
  const [tabClass, setTabClass] = useState("");
  const answerLocked = () => {
    setClicked(true);
    setafteranswer(correct);
    addAnswered();
    if (value === correct) {
      setTabClass("correct");
      addPoint(add);
      setNumberOfPieces(800);
      setTimeout(() => {
        setNumberOfPieces(100);
      }, 1000);
      toast.success(correctMessage);
    } else {
      setTabClass("incorrect");
      subtractPoint(reduce);
      setGravity(0);
      setTimeout(() => {
        setGravity(0.1);
      }, 3000);
      toast.error(inCorrectMessage);
    }
    console.log(correct);
    setOptionClass("option lock");
  };
  const valueChanged = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className={`question-tab ${tabClass}`}>
      <div className="question">{question}</div>
      <div className="options" onChange={valueChanged}>
        <div
          className={`${optionClass} ${
            afterAnswer === "a1" ? "colorGreen" : ""
          }`}
        >
          <input type="radio" value="a1" name={question} />
          <span>{a1}</span>
        </div>
        <div
          className={`${optionClass} ${
            afterAnswer === "a2" ? "colorGreen" : ""
          }`}
        >
          <input type="radio" value="a2" name={question} />
          <span>{a2}</span>
        </div>
        <div
          className={`${optionClass} ${
            afterAnswer === "a3" ? "colorGreen" : ""
          }`}
        >
          <input type="radio" value="a3" name={question} />
          <span>{a3}</span>
        </div>
        <div
          className={`${optionClass} ${
            afterAnswer === "a4" ? "colorGreen" : ""
          }`}
        >
          <input type="radio" value="a4" name={question} />
          <span>{a4}</span>
        </div>
      </div>
      <div className="sure">
        <button
          className={clicked ? "none" : ""}
          onClick={answerLocked}
          disabled={!value}
        >
          Lock It?
        </button>
      </div>
    </div>
  );
};

export default Questions;
