import { Button } from "antd";
import firebase from "firebase";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const { currentUser, loginWithGoogle } = useAuth();
  const loginWithGoogleFunction = () => {
    loginWithGoogle();
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {currentUser ? (
        <Redirect to="/" />
      ) : (
        <Button
          onClick={loginWithGoogleFunction}
          type="primary"
          shape="round"
          size={"large"}
        >
          Login Here
        </Button>
      )}
    </div>
  );
};

export default Login;
