import React from "react";
import "./Portfolio.css";
import pic from "./assets/muscles.png";
import pencil from "./assets/pencil.svg";
import box1 from "./assets/box1.svg";
import box2 from "./assets/box2.svg";
import box3 from "./assets/box3.svg";
import box4 from "./assets/box4.svg";
import key from "./assets/key.svg";
import suitable from "./assets/suitable.svg";
import linkedin from "./assets/linkedin.png";
import instagram from "./assets/instagram.png";
import spotify from "./assets/spotify.png";
import bakhe from "./assets/bakhe.png";

const Portfolio = () => {
  return (
    <div>
      <div className="container">
        <div className="profile">
          <div className="profile-box">
            <div className="profile-head">
              <div className="profile-button"></div>
              <div className="profile-button"></div>
              <div className="profile-button"></div>
            </div>
            <div className="profile-photo">
              <img id="toghiri-photo" src={pic} alt="aarohi" />
            </div>
          </div>
          <svg
            className="frist-star"
            width="92"
            height="86"
            viewBox="0 0 92 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.1238 28.5011L79.9994 19.0556L64.8908 40.1112L64.2327 41.0284L65.2226 41.5711L88.3463 54.2486L61.7326 56.0669L60.6391 56.1416L60.8136 57.2237L64.8964 82.5464L46.7206 63.6566L46 62.9077L45.2794 63.6566L27.1036 82.5464L31.1864 57.2237L31.3609 56.1416L30.2673 56.0669L3.65369 54.2486L26.7774 41.5711L27.7673 41.0284L27.1092 40.1112L12.0006 19.0556L36.8762 28.5011L37.8639 28.8762L38.1842 27.8694L46 3.2989L53.8158 27.8694L54.1361 28.8762L55.1238 28.5011Z"
              fill="#54BA4E"
              stroke="black"
              stroke-width="2"
            />
          </svg>
          <div className="line"></div>
          <img className="pencil" src={pencil} alt="pencil icon" />
          <svg className="arrow1" viewBox="0 0 100 100">
            <path d="M41.5 0V59.1364H3" />
            <path
              d="M0.5 58.6364L8.75 53.8732L8.75 63.3995L0.5 58.6364Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="name">
          <div className="name-box">
            <img className="box1" src={box1} />
            <h1>aarohi poudel</h1>
          </div>
          <svg className="arrow2" viewBox="0 0 92 66">
            <path
              d="M29.5 60.5L21.25 55.7369L21.25 65.2631L29.5 60.5Z"
              fill="black"
            />
            <path d="M91 0.5V26H1V60.5H25" fill="none" />
          </svg>
          <img className="key" src={key} alt="key icon" />
        </div>

        <div className="feild">
          <div className="feild-box">
            <img className="box2" src={box2} />
            <h2>
              I like to speak, write <br /> and exist 😁
            </h2>
          </div>
          <svg className="arrow3" viewBox="0 0 110 100">
            <path
              d="M88.5 55.5L96.75 50.7369L96.75 60.2631L88.5 55.5Z"
              fill="black"
            />
            <path d="M1.5 0V22.5H107.5V55.5H91" fill="none" />
          </svg>
          <img className="suitable" src={suitable} alt="suitable icon" />
        </div>

        <div className="aboutme">
          <div className="title-aboutme">
            <p>About Me</p>
          </div>
          <p className="text-aboutme">
            I done passed my 12th grade. I'm just chilling at the moment. I am
            also one of the (best) directors at Debate Network Nepal. I also cry
            when I'm drunk.
            <br />
            <br />
            I'm blind without my glasses and I like princess diaries, so my
            taste is media is dogshit.
          </p>
          <svg
            className="second-star"
            width="92"
            height="86"
            viewBox="0 0 92 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.1238 28.5011L79.9994 19.0556L64.8908 40.1112L64.2327 41.0284L65.2226 41.5711L88.3463 54.2486L61.7326 56.0669L60.6391 56.1416L60.8136 57.2237L64.8964 82.5464L46.7206 63.6566L46 62.9077L45.2794 63.6566L27.1036 82.5464L31.1864 57.2237L31.3609 56.1416L30.2673 56.0669L3.65369 54.2486L26.7774 41.5711L27.7673 41.0284L27.1092 40.1112L12.0006 19.0556L36.8762 28.5011L37.8639 28.8762L38.1842 27.8694L46 3.2989L53.8158 27.8694L54.1361 28.8762L55.1238 28.5011Z"
              fill="#54BA4E"
              stroke="black"
              stroke-width="2"
            />
          </svg>

          <svg
            className="watch"
            width="25"
            height="40"
            viewBox="0 0 25 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.0153 4.35114H3.771V12.7219L8.99237 18.6696L3.771 24.1767V33.6488H20.0153V24.1767L14.5038 18.6696L20.0153 12.7219V4.35114Z"
              fill="#54BA4E"
              stroke="black"
              stroke-width="2"
            />
            <rect
              x="1"
              y="1"
              width="22.0763"
              height="4"
              fill="#F8D253"
              stroke="black"
              stroke-width="2"
            />
            <rect
              x="1"
              y="34.6489"
              width="22.0763"
              height="4"
              fill="#F8D253"
              stroke="black"
              stroke-width="2"
            />
          </svg>
          <svg
            className="watch soil"
            width="25"
            height="40"
            viewBox="0 0 25 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="7.17939" y="29.8053" width="1.01527" height="1.30534" />
            <rect x="7.50763" y="10.6985" width="1.01527" height="1.30534" />
            <rect x="10.0802" y="29.8053" width="1.01527" height="1.30534" />
            <rect x="10.4084" y="10.6985" width="1.01527" height="1.30534" />
            <rect x="8.62976" y="26.6145" width="1.01527" height="1.30534" />
            <rect x="8.95801" y="7.50763" width="1.01527" height="1.30534" />
            <rect x="12.9809" y="29.8053" width="1.01527" height="1.30534" />
            <rect x="13.3091" y="10.6985" width="1.01527" height="1.30534" />
            <rect x="11.5305" y="26.6145" width="1.01527" height="1.30534" />
            <rect x="11.8588" y="7.50763" width="1.01527" height="1.30534" />
            <rect x="10.0802" y="23.4236" width="1.01527" height="1.30534" />
            <rect x="10.0802" y="13.8511" width="1.01527" height="1.30534" />
            <rect x="15.8817" y="29.8053" width="1.01527" height="1.30534" />
            <rect x="16.2099" y="10.6985" width="1.01527" height="1.30534" />
            <rect x="14.4313" y="26.6145" width="1.01527" height="1.30534" />
            <rect x="14.7595" y="7.50763" width="1.01527" height="1.30534" />
            <rect x="12.9809" y="23.4236" width="1.01527" height="1.30534" />
            <rect x="12.9809" y="13.8511" width="1.01527" height="1.30534" />
            <rect x="11.5305" y="20.2328" width="1.01527" height="1.30534" />
            <rect x="11.5305" y="17.042" width="1.01527" height="1.30534" />
          </svg>
        </div>

        <div className="rotation">
          <div className="ground-rotation">
            <div className="hit-rotation"></div>
            <svg
              className="circle-rotation"
              width="53"
              height="54"
              viewBox="0 0 53 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask id="path-1-inside-1_4_3" fill="white">
                <path d="M53 27.0262C53 41.6618 41.1355 53.5262 26.5 53.5262C11.8645 53.5262 0 41.6618 0 27.0262C0 12.3907 11.8645 0.526245 26.5 0.526245C41.1355 0.526245 53 12.3907 53 27.0262ZM11.8469 27.0262C11.8469 35.1189 18.4073 41.6794 26.5 41.6794C34.5927 41.6794 41.1531 35.1189 41.1531 27.0262C41.1531 18.9336 34.5927 12.3731 26.5 12.3731C18.4073 12.3731 11.8469 18.9336 11.8469 27.0262Z" />
              </mask>
              <path
                d="M53 27.0262C53 41.6618 41.1355 53.5262 26.5 53.5262C11.8645 53.5262 0 41.6618 0 27.0262C0 12.3907 11.8645 0.526245 26.5 0.526245C41.1355 0.526245 53 12.3907 53 27.0262ZM11.8469 27.0262C11.8469 35.1189 18.4073 41.6794 26.5 41.6794C34.5927 41.6794 41.1531 35.1189 41.1531 27.0262C41.1531 18.9336 34.5927 12.3731 26.5 12.3731C18.4073 12.3731 11.8469 18.9336 11.8469 27.0262Z"
                fill="#54BA4E"
                stroke="black"
                stroke-width="4"
                mask="url(#path-1-inside-1_4_3)"
              />
            </svg>
          </div>
        </div>

        <div className="media">
          <h3>media</h3>
        </div>

        <div className="media-grid">
          <div className="social-media-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.aarohipoudel.com.np/"
            >
              <div className="social-media">
                <div className="social-image">
                  <img className="piccc" src={pic} alt="aarohi website" />
                </div>
                <p>.</p>
                <h3>website</h3>
                <div className="click-here">
                  <p>click here</p>
                </div>
              </div>
            </a>
          </div>

          <div className="social-media-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/aarohi-poudel/"
            >
              <div className="social-media">
                <div className="social-image">
                  <img
                    src={linkedin}
                    alt="aarohi linkedin"
                    style={{ height: "40px", width: "auto" }}
                  />
                </div>
                <p>@aarohi-poudel</p>
                <h3>linkedin</h3>
                <div className="click-here">
                  <p>click here</p>
                </div>
              </div>
            </a>
          </div>

          <div className="social-media-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://instagram.com/aarohi_poudel"
            >
              <div className="social-media">
                <div className="social-image">
                  <img
                    src={instagram}
                    alt="aarohi instagram"
                    style={{ height: "40px", width: "auto" }}
                  />
                </div>
                <p>@aarohi_poudel</p>
                <h3>instagram</h3>
                <div className="click-here">
                  <p>click here</p>
                </div>
              </div>
            </a>
          </div>

          <div className="social-media-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://open.spotify.com/user/4uv86m695nw7vfmo3sluyar5i?si=zeSqPJh_QkG0kFvAXGftWA&utm_source=copy-link"
            >
              <div className="social-media">
                <div className="social-image">
                  <img
                    src={spotify}
                    alt="aarohi spotify"
                    style={{ height: "40px", width: "auto" }}
                  />
                </div>
                <p>@aarohi</p>
                <h3>spotify</h3>
                <div className="click-here">
                  <p>click here</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="rotation">
          <div className="happy-man">
            <div className="line-animation">
              <img
                className="happy-man-icon"
                src={bakhe}
                alt="happy man icon"
              />
            </div>
          </div>
        </div>

        <div className="media">
          <h3>contact me</h3>
        </div>

        <div className="number">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="tel:+9779813262961"
          >
            <div className="number-box">
              <img className="box3" src={box3} />
              <h4>+977 9813262961</h4>
            </div>
          </a>
          <svg className="arrow6" viewBox="0 0 100 100">
            <path d="M37 1H45V32H23.5V41H45V50.5H2.5" fill="none" />
            <path d="M-2.40413e-07 50.5L8.25 45.7369L8.25 55.2631L-2.40413e-07 50.5Z" />
          </svg>
        </div>

        <div className="email">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:poudelaarohi123@gmail.com"
          >
            <div className="email-box">
              <img className="box4" src={box4} />
              <h4>poudelaarohi123@gmail.com</h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
