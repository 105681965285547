import React from "react";
import './DonkeyBrains.css';
import image from "../../assets/FinalMaybe.png";

const DonkeyBrains = () => {
  return (
    <div className="donkey-brains">
      <img src={image} alt="donkey-brains" />
    </div>
  );
};

export default DonkeyBrains;
