import React, { useState } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import "./Home.css";
import Questions from "./Questions/Questions";
import { Link } from "react-router-dom";

const Home = () => {
  const { width, height } = useWindowSize();
  const [numberOfPieces, setNumberOfPieces] = useState(100);
  const [gravity, setGravity] = useState(0.1);
  const [score, setScore] = useState(0);
  const [answered, setAnswered] = useState(0);

  const addPoint = (add) => {
    setScore(score + add);
  };
  const subtractPoint = (reduce) => {
    setScore(score - reduce);
  };

  const addAnswered = () => {
    setAnswered(answered + 1);
  };

  return (
    <div className="home">
      <Confetti
        width={width - 10}
        height={height - 10}
        numberOfPieces={numberOfPieces}
        gravity={gravity}
        opacity={0.95}
        style={{ position: "fixed" }}
      />
      <div className="points">Points: {score}</div>
      <h1>
        Hello, <span>Mr Bhakada</span>
      </h1>
      <h2>Heard it's your birthday or something today.</h2>

      <div className="training">
        Remember when I made us do a quiz like the week we met? <br />
        Yeah, that was me training you for this one.
        <br />
        Forget Yale and Harverd, this is the most important quiz you'll ever do.{" "}
        <span className="not-really">(not really)</span>
        <br />
        also, cheat nagar. dont even think of looking anything up
        <br />
        <br />
        <br />
        LET'S GO
      </div>
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="What week-day is my birthday?"
        a1="Saturday"
        a2="Sunday"
        a3="Tuesday"
        a4="Friday"
        correct="a3"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={0}
        add={5}
        correctMessage="You Lucky Bitch!!"
        inCorrectMessage="Not reducing any points, because this is supa hard!"
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="Among all the songs you've made me hear, which one's my favoriate?"
        a1="Vincent"
        a2="Tamblorine Man"
        a3="Hookah Bar"
        a4="That indian dude songs"
        correct="a1"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="This one was pretty easy."
        inCorrectMessage="No way, you got this wrong!"
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="What side of my face is my kothi?"
        a1="Above lip, my right"
        a2="Above lip, my left"
        a3="Below lip, my right"
        a4="Below lip, my left"
        correct="a3"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="I've asked you this before, so no shite!"
        inCorrectMessage="Wowwwwwwwwwwwwwwwwwwwwwwwww! OK."
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="What's your name in facebook messanger?"
        a1="Pokhara's best dick sucker"
        a2="Pokhara's best cock sucker"
        a3="pokhara's best dick sucker"
        a4="Pokhara's Best Dick Sucker"
        correct="a1"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="Yeah, its your name. Of course you got it right."
        inCorrectMessage="I've got nothing to say. 😁"
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="Remember my bear t-shirt? What's the bear holdin?"
        a1="Skateboard"
        a2="Longboard"
        a3="Hoverboard"
        a4="Surfboard"
        correct="a4"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={2}
        add={4}
        correctMessage="You did tell me you like the shirt."
        inCorrectMessage="Wow, and you told me you like it huh! ok!"
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="Whats our snap streak as of right now?"
        a1="95"
        a2="91"
        a3="93"
        a4="97"
        correct="a3"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={2}
        add={4}
        correctMessage="Esnap Estrick! Good."
        inCorrectMessage="Taile maya gardinas thaa thyo."
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="Whats our first message in insta (not counting the link)?"
        a1="poophead"
        a2="come here"
        a3="clicky"
        a4="ready? im not"
        correct="a1"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="Malai kina poophead bhanis boksi."
        inCorrectMessage="Poophead vanne boksi lai yestai hunchha."
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="One of these is a direct quote from your muslim boyfriend. Which?"
        a1="Sex is very bad Allah say that"
        a2="You do sex you no longer love by God"
        a3="Every day you do sex your body is down"
        a4="Sex before marry make you go under hell"
        correct="a3"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="He's right."
        inCorrectMessage="You never listen to voice of reason anyway."
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="Whats my Patronus?"
        a1="Pheasant"
        a2="Grouse"
        a3="Quail"
        a4="Turkey"
        correct="a1"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="STFU u a crow."
        inCorrectMessage="crow ass bitch hahahah."
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="Who wrote your review for 'Murder Mistery'?"
        a1="@the.jack.skellington"
        a2="@im.jack.skellington"
        a3="@im_jack_skillington"
        a4="@im.jack.skillington"
        correct="a2"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="Mero insta ghumchhu vanthis. ho ki kya ho."
        inCorrectMessage="I vIsIt YoUr InSta OnCe a DaY"
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="We did the SAT test. How much points we got outta 10?"
        a1="7"
        a2="8"
        a3="9"
        a4="10"
        correct="a2"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="ho hai ho bathi chhe kei"
        inCorrectMessage="Talai kei tha chaina morr"
      />
      <Questions
        setNumberOfPieces={setNumberOfPieces}
        setGravity={setGravity}
        question="What was the date we met? (you know this you told me A-One ma 😁), so the new question:  How many feet are in a mile?"
        a1="5230"
        a2="5210"
        a3="5270"
        a4="5280"
        correct="a4"
        addPoint={addPoint}
        addAnswered={addAnswered}
        subtractPoint={subtractPoint}
        reduce={3}
        add={4}
        correctMessage="Guess garera milai pakkai"
        inCorrectMessage="hehe shouldnt have said it lololololol"
      />
      {answered === 12 ? (
        <div className="end-message">
          So, You got {score} points huh.
          {score < 20 ? (
            <>
              <br />
              Not Good Enough, Reload Page. Try again. 😡😡😡😡
            </>
          ) : (
            <>
              <br />
              Okayyyyyy! Here Click <Link to="/you-win">HERE</Link> to see
              something hehe.
            </>
          )}
        </div>
      ) : (
        <div className="end-message">FINISH IT</div>
      )}
    </div>
  );
};

export default Home;
