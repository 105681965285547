import firebase from "../../../../firebase";
import { dateToString } from "../../../../ReuseThese/JSDateTIme";
import "./SenderMessage.css";

const SenderMessage = ({ message }) => {
  return (
    <div className="whoileee">
      <div className="sendermessage">
        <p>{message.message}</p>
      </div>
      <span className="timeee">{dateToString(new Date(message.time))}</span>
    </div>
  );
};

export default SenderMessage;
