import { AutoComplete, Menu } from "antd";
import React, { useEffect, useRef, useState } from "react";
import firebase, { db } from "../../../firebase";

const { Option } = AutoComplete;

const LeftSide = ({
  mates,
  setMates,
  messanger,
  setMessanger,
  userID,
  userName,
}) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState();
  const [itemValues, setItemValues] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const handleChange = (newValue, dunno) => {
    const check = mates.find((mate) => mate.userId === newValue);

    console.log(dunno);

    if (check === undefined) {
      db.collection("mates")
        .doc()
        .set({
          ids: [dunno.key, userID],
          names: [dunno.children, userName],
          time: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    //POINT TO CHECK
  };

  const handleSearch = (newValue) => {
    setData([]);
    setValue(newValue);
    if (newValue.length < 1) {
      return;
    }

    if (newValue) {
      const delayDebounceFn = setTimeout(() => {
        db.collection("users")
          .where("nameLower", ">=", newValue.toLowerCase())
          .where("nameLower", "<=", newValue.toLowerCase() + "\uf8ff")
          .onSnapshot((snapshot) => {
            const users = [];
            snapshot.docs.forEach((doc) => {
              users.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            setData(users);
          });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    let item = [];
    item = mates.map((mate) => {
      return getItem(mate.userName || "You", mate.userId);
    });
    setItemValues([...item]);
  }, [mates]);

  return (
    <div style={{ height: "100%" }}>
      <AutoComplete
        showSearch
        value={value}
        placeholder={"Search Users"}
        onSearch={handleSearch}
        onSelect={handleChange}
        notFoundContent={"NO USERS FOUND"}
        style={{ minWidth: "100%" }}
      >
        {data.map((d) => (
          <Option key={d.id} value={d.id}>
            {d.name}
          </Option>
        ))}
      </AutoComplete>

      {itemValues.length > 0 ? (
        <Menu
          selectedKeys={[messanger]}
          mode="inline"
          items={itemValues}
          style={{ width: 300 }}
          theme="light"
          onClick={(e) => {
            console.log(e);
            setMessanger(e.key);
          }}
        />
      ) : (
        <div>No users found</div>
      )}
    </div>
  );
};

export default LeftSide;
