import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, googleOAuth, db } from "../firebase";
import { toast } from "react-toastify";
import { message } from "antd";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState("");
  const [allowNewUser, setAllowNewUser] = useState(false);

  useEffect(() => {
    db.collection("toggles")
      .doc("toggles")
      .onSnapshot((doc) => {
        setAllowNewUser(doc.data().allowNewUser);
      });
  }, []);

  const loginWithGoogle = async () => {
    try {
      const userCredential = await auth.signInWithPopup(googleOAuth);
      const isNewUser = userCredential.additionalUserInfo.isNewUser;
      var loggedInUser = userCredential.user;
      if (isNewUser && !allowNewUser) {
        //delete user if the user is new
        loggedInUser.delete().then(() => {
          logOut();
          toast.error("You are not allowed to login. Ask the boss.");
        });
        return;
      }

      try {
        const userDoc = await db
          .collection("users")
          .doc(userCredential.user.uid)
          .get();

        if (userDoc.exists) {
          return;
        } else {
          await db.collection("users").doc(userCredential.user.uid).set({
            name: userCredential.user.displayName,
            email: userCredential.user.email,
            nameLower: userCredential.user.displayName.toLowerCase(),
          });
        }
      } catch (err) {
        console.log(err);
        message.error(err.message || "Failed saving information");
      }
    } catch (err) {
      console.log(err);
      message.error(err.message || "Logging in with google failed");
    }
  };

  const logOut = () => {
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        currentUser.getIdTokenResult().then((user) => {
          setCurrentUser(user);
          setAuthLoading(false);
        });
      } else {
        setCurrentUser("");
        setAuthLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  const values = {
    currentUser,
    authLoading,
    loginWithGoogle,
    logOut,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
