import React, { useState, createContext, useContext, useEffect } from "react";
import { useAuth } from "./AuthContext";
import firebase, { db, storageRef } from "../firebase";

const DataContext = createContext();
export const useData = () => {
  return useContext(DataContext);
};

const DataProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const values = {};

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

export default DataProvider;
