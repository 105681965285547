import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "../ReuseThese/Spinner";
import Login from "./Login";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser, authLoading } = useAuth();

  return (
    <Route {...rest}>
      {authLoading ? <Spinner /> : currentUser ? <Component /> : <Login />}
    </Route>
  );
};

export default PrivateRoute;
