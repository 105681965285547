import TextArea from "antd/lib/input/TextArea";
import React, { useRef } from "react";
import firebase, { db } from "../../../../firebase";

const ChatInput = ({ messanger, userID }) => {
  const [value, setValue] = React.useState("");

  const sendMessageToFirebase = () => {
    if (!value) {
      return;
    }
    db.collection("messages").add({
      sender: userID,
      receiver: messanger,
      message: value,
      time: Date.now(),
      uid: [userID, messanger].sort().join(""),
    });

    setValue("");
  };

  return (
    <div>
      <TextArea
        style={{
          width: "100%",
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Your Message.."
        autoSize={{ minRows: 3, maxRows: 5 }}
        onPressEnter={sendMessageToFirebase}
      />
    </div>
  );
};

export default ChatInput;
